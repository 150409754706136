@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Vibur&display=swap');

body {
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #FFFFFF;
  overflow: hidden;  /* Disable scroll */
  font-size: 18px;
}

.text-neon-red {
  color: #FF0000;
  text-shadow: 0 0 5px #FF0000, 0 0 10px #FF0000, 0 0 20px #FF0000;
}

.neon-button {
  display: block;
  padding: 20px;
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  background: #222;
  border: 1px solid;
  border-radius: 15px;
  font-family: 'Vibur', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon-green {
  background: #16894D;
}

.neon-yellow {
  background: #E4A73F;
}

.neon-button:hover {
  box-shadow: 0 0 30px, 0 0 60px, inset 0 0 20px;
}

.bg-neon-white {
  background-color: #FFFFFF;
}

.fixed {
  position: fixed;
}

.top-4 {
  top: 2rem;
}

.w-48 {
  width: 12rem;
}

.h-48 {
  height: 12rem;
}

.rounded-full {
  border-radius: 9999px;
}

.mt-48 {
  margin-top: 12rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.text-2xl {
  font-size: 2.5rem;
}

.flash1 {
  animation: flickerText 1.5s infinite alternate;
}

.flash2 {
  animation: flickerText 4.5s infinite alternate;
}

/* Styles for the neon border around the logo with flickering effect */
.neon-logo {
  transition: box-shadow 0.3s ease-in-out;
  animation: flicker 2.5s infinite alternate;
}

@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    box-shadow: 
    0 0 1px #E4A73F,
      0 0 3px #E4A73F,
      0 0 11px #E4A73F,
      0 0 15px #E4A73F,
      0 0 20px #fff,
      0 0 25px #E4A73F,
      0 0 30px #fff,
      0 0 33px #fff;
  }
  20%, 24%, 55% {        
    box-shadow: none;
  }
}

@keyframes flickerText {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow: 
    0 0 1px #fff,
      0 0 3px #fff,
      0 0 11px #fff,
      0 0 20px #fff,
      0 0 30px #fff,
      0 0 90px #fff;
  }
  20%, 24%, 55% {        
    text-shadow: none;
  }
}

h1, h2 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
}
